import * as Sentry from '@sentry/sveltekit';
import { dev } from '$app/environment';

// Add a wait function if you need to delay in dev mode
const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Conditionally wait in dev mode before initializing
(async () => {
	if (dev) await wait(1); // Wait 1ms in development mode

	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_DSN,
		enabled: !dev, // Only enable Sentry in production
		tracesSampleRate: 1.0,
		integrations: [Sentry.replayIntegration()],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0
	});
})();

/** @type {import('@sveltejs/kit').HandleClientError} */
export async function handleError({ error, event, status, message }) {
	const errorId = crypto.randomUUID();

	// example integration with https://sentry.io/
	Sentry.captureException(error, {
		extra: { event, errorId, status, message }
	});

	return {
		message: 'Whoops!, ' + message,
		errorId
	};
}
