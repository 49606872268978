import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92')
];

export const server_loads = [];

export const dictionary = {
		"/": [19],
		"/activity-logs": [20],
		"/analysis": [21,[2]],
		"/analysis/session": [22,[2]],
		"/analysis/star": [23,[2]],
		"/analysis/subject": [24,[2]],
		"/analysis/term": [25,[2]],
		"/broadsheet": [26],
		"/classrooms": [27,[3]],
		"/classrooms/[id]": [28,[3]],
		"/credits": [29],
		"/dashboard": [30],
		"/e-learning": [31,[4]],
		"/e-learning/[id]": [32,[4]],
		"/finance/dashboard": [33],
		"/finance/fee-schedule": [34,[5]],
		"/finance/fee-schedule/classroom": [35,[5]],
		"/finance/fee-schedule/classroom/[id]": [36,[5]],
		"/finance/fee-schedule/classroom/[id]/student/[sid]": [37,[5]],
		"/finance/income": [38],
		"/finance/outstanding": [39,[7]],
		"/finance/outstanding/[id]": [40,[7]],
		"/finance/split-fees": [41],
		"/finance/student-fees": [42],
		"/finance/students-accounts": [43],
		"/finance/transaction-history": [44,[8]],
		"/finance/transaction-history/[id]": [45,[8]],
		"/login": [46],
		"/mobile/scoresheet": [47],
		"/mobile/subject-comments": [48],
		"/non-academics": [49,[9]],
		"/non-academics/attendance": [50,[9]],
		"/non-academics/observation": [51,[9]],
		"/non-academics/psychomotor": [52,[9]],
		"/non-academics/spiritual-development": [53,[9]],
		"/parents": [54,[10]],
		"/parents/split-fees": [55,[10]],
		"/parents/students": [56,[10,11]],
		"/parents/students/[id]": [57,[10,11]],
		"/report-comments": [58],
		"/reports/promotion-repeated-list": [59],
		"/reports/verification": [60],
		"/scoresheet": [61,[12]],
		"/scoresheet/[id]": [62,[12]],
		"/settings": [63,[13]],
		"/settings/ca-scheme": [65,[13]],
		"/settings/grading-scheme": [66,[13]],
		"/settings/logo": [67,[13]],
		"/settings/publish-result": [68,[13]],
		"/settings/remove-leaked-data": [69,[13]],
		"/settings/report-promotion": [70,[13]],
		"/settings/signature": [71,[13]],
		"/settings/term": [72,[13]],
		"/settings/[id]": [64,[13]],
		"/staff": [73,[14]],
		"/staff/add": [75,[14]],
		"/staff/recruitment": [76,[14]],
		"/staff/[id]": [74,[14]],
		"/students": [77,[15]],
		"/students/add": [79,[15]],
		"/students/import": [80,[15]],
		"/students/move": [81,[15]],
		"/students/[id]": [78,[15]],
		"/subject-comments": [82],
		"/subjects": [83,[16]],
		"/subjects/add": [85,[16]],
		"/subjects/[id]": [84,[16]],
		"/teachers": [86,[17]],
		"/teachers/add": [88,[17]],
		"/teachers/[id]": [87,[17]],
		"/users": [89,[18]],
		"/users/add": [91,[18]],
		"/users/[slug]": [90,[18]],
		"/visualize-report": [92]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';